<template>
  <div class="exchange-content">
    <div class="head-content" ref="element">
      <div class="or-header">
        <div class="head-left">
          <div class="head-type">
            <p @click="showScreenPopup = !showScreenPopup">团购兑换订单<span class="arrow-top"></span></p>
            <!--                        <p @click="showScreenPopup = !showScreenPopup">{{brandId == 4?'蔓薇':(brandId == 3?'梵洁诗':'香蜜')}}-->
            <!--                            <span class="brand-digit" v-if="orderCountByBid > 0">({{orderCountByBid}})</span>-->
            <!--                            <span class="icon arrow-top"></span>-->
            <!--                        </p>-->
          </div>
        </div>
        <div class="head-right" @click="showScreenPopup = !showScreenPopup">
          筛选 <span class="icon-sx1"></span>
        </div>
      </div>
      <div class="or-nav">
        <van-tabs v-model="tabId" @change="changeState">
          <van-tab v-for="(item,index) in tabList" :key="index">
            <div slot="title">
              {{ item.text }}<!---->
              <template v-if="item.Count > 0">({{ item.Count }})</template>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <van-list v-model="loading" :finished="finished" @load="pullUpLoadMore" ref="orderList">
      <ListItem :order-list="orderList" :tab-id="tabId" :order-type="10" @cancel="handleCancel"
                @pay="confirmPay"/>
    </van-list>
    <div class="no-data" v-if="finished && orderList.length < 1 ">
      <div class="bg-circle">
        <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210414140256672.png">
      </div>
    </div>
    <van-popup v-model="showCancelLayer">
      <Cancel @cancel="confirmCancel"/>
    </van-popup>

    <screenPopup @close="showScreenPopup = false" :show="showScreenPopup" @search="search"></screenPopup>
  </div>
</template>

<script>
import ListItem from "../../components/Order/ListItem";
import Cancel from "../../components/Order/Cancel";
import SelectPayType from "../../components/Order/SelectPayType";
import {wxPay} from "../../utils/wxPay"
import screenPopup from "../../components/screenPopup";


export default {
  name: "ExchangeList",
  components: {ListItem, Cancel, SelectPayType, screenPopup},
  data() {
    return {
      tabId: 0,
      tabList: [{text: '待付款', Count: 0}, {text: '待发货', Count: 0}, {text: '已发货', Count: 0}, {
        text: '已取消',
        Count: 0
      }],
      finished: false,
      loading: false,
      orderList: [],
      searchVal: '',
      pageIndex: 0,
      pageSize: 10,
      showCancelLayer: false,
      currentOrder: 0, //当前选中的订单
      showPayLayer: false,
      orderCountByBid: 0,
      brandId: 0,
      showScreenPopup: false,
      startTime: '',
      endTime: ''
    }
  },
  created() {
    if (this.$route.query.searchVal) {
      this.searchVal = this.$route.query.searchVal
    }
    if (this.$route.query.startTime) {
      this.startTime = this.$route.query.startTime
    }
    if (this.$route.query.endTime) {
      this.endTime = this.$route.query.endTime
    }
  },
  methods: {
    search(e) {
      // console.log(e)
      this.pageIndex = 0
      this.finished = false
      this.searchVal = e.orderNum
      this.startTime = e.timeArr[0]
      this.endTime = e.timeArr[1]
      this.orderList = []
      this.brandId = e.brandType
      this.getOrderList()
      this.showScreenPopup = false
    },
    //上拉加载更多
    pullUpLoadMore() {
      this.pageIndex++;
      this.getOrderList();
    },
    //获取订单列表
    getOrderList() {
      this.loading = false
      if (this.loading) return
      this.loading = true
      this.post('/OrderGoods/FixedExchange/GetFixedExchangeOrderList', {
        start: this.pageIndex,
        length: this.pageSize,
        startTime: this.startTime,
        endTime: this.endTime,
        startPrice: '',
        endPrice: '',
        searchVal: this.searchVal,
        orderBy: 1,
        stateType: this.tabId + 1
      }, true, 2).then(res => {
        this.loading = false;
        if (res.code === 1) {
          let {orderList, orderStateCount} = res.response;
          if (orderList.length < this.pageSize) {
            this.finished = true;
          }
          this.tabList.forEach((it, idx) => {
            // if (idx === 3) return
            it.Count = orderStateCount["state" + (idx + 1)] || 0
          });
          orderList.forEach(item => {
            item.OrderNum = item.orderNum;
            let totalNum = 0;
            item.ProductList = (() => {
              let arr = [];
              item.goodsList.forEach(it => {
                arr.push({
                  ImageUrl: it.goodsImg,
                  GoodsName: it.goodsName,
                  Amount: it.goodsPrice,
                  Count: it.goodsCount,
                  goodsSpecifications: it.goodsSpecifications
                })
                totalNum += it.goodsCount
              });
              return arr;
            })();
            item.totalNum = totalNum;
            item.btnList = (() => {
              let arr = [];
              if ([0, 10].indexOf(item.state) >= 0) arr.push({
                text: '取消订单',
                type: 1,
                className: '',
                showType: 1
              });
              if (item.state === 0) arr.push({text: '去付款', type: 2, className: 'col', showType: 1});
              return arr;
            })();
          });
          if (this.pageIndex === 1) this.orderList = [];
          this.orderList = this.orderList.concat(orderList);
        }
      });
    },
    //点击状态、根据状态获取数据
    changeState(id) {
      this.tabId = id;
      this.initOrderParam();
    },
    initOrderParam() {
      this.loading = true;
      this.finished = false;
      this.pageIndex = 1;
      this.orderList = [];
      this.getOrderList(1);
    },
    //点击取消订单
    handleCancel(item) {
      this.$dialog.confirm({title: '提示', message: '是否确认取消该订单'}).then(() => {
        this.currentOrder = item;
        this.showCancelLayer = true;
      }).catch(() => {
      });
    },
    //确认取消订单
    confirmCancel(e) {
      let {type, message} = e;
      this.showCancelLayer = false;
      let {orderId} = this.currentOrder
      if (type === 1) return
      this.$toast.loading({message: '订单取消中,,,', overlay: true, duration: 0})
      this.post("/OrderGoods/FixedExchange/CancelFixedExchangeOrderV2", {
        orderId: orderId,
        reasons: message
      }, true, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          this.$toast("取消成功!");
          this.deleteFromList(orderId);
        } else {
          this.$toast(res.msg);
        }
      });
    },
    //确认支付
    confirmPay(item) {
      return this.$router.push("/Recharge/order_detail?orderId=" + item.orderId + "&orderType=10");
      this.$dialog.confirm({title: '提示', message: '是否确认支付该订单'}).then(() => {
        this.$toast.loading({message: '支付中...', overlay: true, duration: 0})
        this.post("/OrderGoods/FixedExchange/PayFixedExchangeOrder", {
          orderId: item.orderId,
          payType: 1
        }, true, 2).then(res => {
          this.$toast.clear();
          if (res.code === 1) {
            let data = res.response;
            wxPay({
              appId: data.appid, timeStamp: data.timeStamp, nonceStr: data.nonceStr,
              paySign: data.sign, signType: data.signType, package: data.package
            }).then(() => {
              this.$toast("支付成功");
              this.deleteFromList(item.orderId);
            }).catch(msg => {
              this.$toast(msg);
            })
          }
        })
      }).catch(() => {
      })
    },
    //从数组里删除数据
    deleteFromList(orderId) {
      let idx = this.orderList.findIndex((it => {
        return it.orderId === orderId
      }));
      if (idx >= 0) {
        --this.tabList[this.tabId].Count;
        this.orderList.splice(idx, 1);
      }
      if (this.orderList.length < 4) {
      }
    }
  },

}
</script>

<style lang="scss" scoped>
.exchange-content {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: #F8F8F8;

  .no-data {
    padding-top: 120px;
    text-align: center;

    .bg-circle {
      position: relative;
      margin-top: 10%;
      margin-bottom: 6%;

      img {
        max-width: 36%;
      }

      .icon-order {
        margin-left: -16px;

        &:before {
          content: "\e966";
        }
      }
    }

    .title {
      font-size: 18px;
      color: #333333;
      line-height: 30px;
      padding-top: 10px;
    }

    .tip {
      color: #999999;
      line-height: 30px;
    }
  }

  .head-content {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;

    .or-header {
      background: #fff;
      padding: 10px 15px 0;
      font-size: 0;

      .head-left {
        width: calc(100% - 50px);
        display: inline-block;
        text-align: start;

        .head-type {
          p {
            font-size: 14px;
            color: #ff98a0;
            display: inline-block;
            background: #fff5f6;
            border-radius: 30px;
            padding: 3px 18px;
            margin-bottom: 10px;
            position: relative;
            border: 1px solid #ff98a0;

            &:nth-child(2) {
              margin-left: 5px;
            }

            .icon {
              width: 7px;
              height: 7px;
              display: inline-block;
              border-top: 1px solid #ff98a0;
              border-right: 1px solid #ff98a0;
              transform: rotate(135deg);
              -webkit-transform: rotate(135deg);
              position: relative;
              left: 7px;
              top: -3px;
            }

            .brand-digit {
            }
          }
        }
      }

      .head-right {
        font-size: 14px;
        color: #000000;
        width: 50px;
        display: inline-block;
        text-align: right;
        vertical-align: top;
      }
    }

    .or-nav {
      border-top: 1px solid #f1f1f1;

      .van-tabs__wrap--scrollable .van-tab {
        flex: 0 0 19%;
      }

      /deep/ .van-tab--active {
        color: #ff98a0;
      }

      /deep/ .van-tabs__line {
        background-color: #ff98a0;
      }

      .brand-digit {
        position: absolute;
        top: 0;
        right: 0;
        color: #FF4444;
        font-size: .6em;
        line-height: 11px;
        padding: 3px 5px;
        background-color: #ffeded;
        border: 1px solid #ff534a;
        border-radius: .7em;
      }
    }
  }

  .van-list {
    padding: 0 0 10px;
  }

  .van-popup {
    border-radius: 6px;
  }

}
</style>